<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container flex-1 flex">
                <div>
                    <el-form ref="form" label-width="110px">
                        <el-row :gutter="10">
                            <el-col :span="4" v-for="(item, index) in airState" :key="index">
                                <el-card class="card" :class="{ 'airCard': item.airOnOff === '开', 'airCardGray': item.airOnOff === '关' }">
                                    <el-container>
                                        <el-aside width="110px">
                                            <div class="cardLeft">
                                                <p class="text">{{ item.name }}</p>
                                                <p class="cardSwitch">{{ item.airOnOff }}</p>
                                            </div>
                                        </el-aside>
                                        <div class="vertical-line"></div>
                                        <el-main class="main">
                                            <div class="cardRight">
                                                <div class="temp">
                                                    <label class="text">{{ item.setTemp }}</label>
                                                    <img src="../../assets/images/set_temp.png" class="icon" alt="" />
                                                </div>
                                                <div class="temp">
                                                    <label class="text">{{ item.indoorTemp }}</label>
                                                    <img src="../../assets/images/indoor_temp.png" class="icon" alt="" />
                                                </div>
                                            </div>
                                        </el-main>
                                    </el-container>
                                </el-card>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "../../components/head.vue";
import Sidebar from "../../components/sidebar.vue";
import breadInfo from "../../components/breadInfo.vue";
import ReconnectingWebSocket from "reconnecting-websocket";
export default {
    data() {
        return {
            airState: [{
                name: "部长办公室",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "1号电梯过道",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "电气办公室右",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "电气办公室左",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "电气办公室外过道",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "休息间",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "小会议室左",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "小会议室右",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "实验室外间",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "大会议室左",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "大会议室中",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "大会议室右",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "健身室",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "茶室",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "包间左",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "包间右",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "系统技术部左",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "系统技术部右",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            }, {
                name: "实验室外过道",
                airMode: '',
                airOnOff: '',
                indoorTemp: '',
                setTemp: ''
            },]
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() {
        this.createWebsocket();
    },
    mounted() { },
    watch: {},
    methods: {
        //分离channelName末尾数字
        separateString(str) {
            const match = str.match(/(\d{1,2})$/);
            if (match) {
                // 如果有匹配到数字，将数字和字母分离出来
                const letters = str.replace(match[0], '').trim();
                const numbers = match[1];
                return { letters, numbers };
            } else {
                // 如果没有匹配到数字，返回原始字符串
                return { letters: str, numbers: '0' };
            }
        },
        // 创建 websocket 链接
        createWebsocket() {
            const httpURL = "43.143.218.144:9084/websocket";
            let token = sessionStorage.getItem("userToken");
            this.websocket = new ReconnectingWebSocket(
                `ws://${httpURL}?Authorization=${token}`
            );
            // 连接发生错误的回调方法
            this.websocket.onerror = this.websocketOnerror;
            // 连接成功建立的回调方法
            this.websocket.onopen = this.websocketOnopen;
            // 接收到消息的回调方法
            this.websocket.onmessage = this.websocketOnmessage;
            // 连接关闭的回调方法
            this.websocket.onclose = this.websocketOnclose;
            // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
            this.websocket.onbeforeunload = this.websocketOnbeforeunload;
        },
        // 连接发生错误的回调方法
        websocketOnerror() {
            console.log("连接发生错误的回调方法");
        },
        // 连接成功建立的回调方法
        websocketOnopen() {
            console.log("连接成功建立的回调方法");
        },
        // 接收到消息的回调方法
        websocketOnmessage(event) {
            const data = JSON.parse(event.data);
            if (data.method == "activeValue") {
                let keys = Object.keys(data.channelValues)
                keys.forEach((item, index) => {
                    let number = Number(this.separateString(item).numbers) - 1
                    if (number != -1) {
                        let letter = this.separateString(item).letters
                        if (letter === 'gree0/AirMode') {
                            this.airState[number].airMode = data.channelValues[item]
                        } else if (letter === 'gree0/AirOnOff') {
                            this.airState[number].airOnOff = data.channelValues[item]
                        } else if (letter === 'gree0/IndoorTemp') {
                            this.airState[number].indoorTemp = data.channelValues[item]
                        } else if (letter === 'gree0/SetTemp') {
                            this.airState[number].setTemp = data.channelValues[item]
                        } else {

                        }
                    }
                })
            }

        },
        // 连接关闭的回调方法
        websocketOnclose() {
            console.log("连接关闭的回调方法");
        },
        // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常
        websocketOnbeforeunload() {
            this.closeWebSocket();
            console.log(
                "监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常"
            );
        },
        // 关闭WebSocket连接
        closeWebSocket() {
            this.websocket.close();
        },
    },
    destroyed() {
        this.closeWebSocket();
    },
}

</script>

<style scoped>
.icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.card{
    margin-top: 20px;
    width: 230px;
    height: 100px;
    background-color: gray;
    color: grey;
}
.airCard {
    background-color: #c2681f;
    border: #300757;
}

.airCardGray{
    background-color: gray;
    border: gray;
    filter: grayscale(100%);
    color: #000;
}

.main{
    padding: 0;
}
.vertical-line{
    width: 1px; 
    background-color: #000; 
    height: 100%;
}
.cardLeft{
    display: flex;
    flex-direction: column;
}
.cardLeft .text{
    font-size: 12px;
}
.cardLeft .cardSwitch{
    margin-top: 10px;
}

cardRight{
    display: flex;
    flex-direction: column;
}
.cardRight .temp{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}
</style>
